import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { SaldoPayment, SaldoPayments } from '@/domain/entities/SaldoPayment'
import { SaldoPaymentPresenter } from '../presenters/SaldoPaymentPresenter'
import { RETRIEVED_SALDO_PAYMENT_PAGINATION } from '@/app/infrastructures/misc/Constants'

export interface SaldoPaymentState {
  isLoading: boolean
  isDownloading: boolean
  saldoPaymentData: SaldoPayment[]
  paginationData: Pagination
}

@Module({ namespaced: true, store, name: 'saldo-payment', dynamic: true })
class SaldoPaymentController extends VuexModule implements SaldoPaymentState {
  private presenter: SaldoPaymentPresenter = container.resolve(
    SaldoPaymentPresenter
  )
  public isLoading = false
  public isDownloading = false
  public saldoPaymentData = [new SaldoPayment()]
  public paginationData = new Pagination(
    1,
    RETRIEVED_SALDO_PAYMENT_PAGINATION,
    0
  )

  @Action({ rawError: true })
  public getSaldoPaymentList(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setSaldoPaymentData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Saldo Paymmet Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFile(params: Record<string, any>) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then(res => {
        EventBus.$emit(EventBusConstants.DOWNLOAD_SALDO_PAYMENT_READY, {
          downloadUrl: res,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Mutation
  private setSaldoPaymentData(payments: SaldoPayments) {
    this.paginationData = payments.pagination as Pagination
    this.saldoPaymentData = payments.data as SaldoPayment[]
  }

  @Mutation
  private setDownloading(bool: boolean) {
    this.isDownloading = bool
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }
}

export default getModule(SaldoPaymentController)
