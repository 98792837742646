




























































































import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import controller from '@/app/ui/controllers/SaldoPaymentController'
import { Component, Watch, Vue } from 'vue-property-decorator'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { RETRIEVED_SALDO_PAYMENT_PAGINATION } from '@/app/infrastructures/misc/Constants'

dayjs.extend(dayjsUTC)

@Component({
  components: {
    DateTimePicker,
    DataTable,
    DownloadIcon,
    PaginationNav,
    Button,
  },
})
export default class RetrievedPayment extends Vue {
  controller = controller
  parameters: {
    page: number
    perPage: number
    dateFrom: string | null
    dateTo: string | null
  } = {
    page: 1,
    perPage: RETRIEVED_SALDO_PAYMENT_PAGINATION,
    dateFrom: null,
    dateTo: null,
  }
  headers = [
    'Invoice ID',
    'User ID',
    'Amount',
    'Status ID',
    'Created At',
    'Expired At',
    'Initiated At',
    'Finished At',
    'Reminder',
    'Reminder At',
    'Payment Method',
    'Package Price',
    'Package ID',
    'Package Created At',
    'Total Amount Invoice',
    'Saldo Amount Used',
    'Transaction Status',
    'Bank',
    'Payment Type',
    'VA Number',
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) ||
          RETRIEVED_SALDO_PAYMENT_PAGINATION,
        dateFrom: queries.dateFrom,
        dateTo: queries.dateTo,
      }
    }
    this.fetchSaldoPayment()

    EventBus.$on(
      EventBusConstants.DOWNLOAD_SALDO_PAYMENT_READY,
      (instance: { downloadUrl: string }) => {
        const link = document.createElement('a')
        link.href = instance.downloadUrl
        link.setAttribute('download', 'file.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    )
  }

  get posDataTable(): Array<Array<string | undefined>> {
    return controller.saldoPaymentData.map(payment => [
      payment.invoiceId,
      payment.customerId,
      `Rp. ${Utils.currencyDigit(Number(payment.amount))}`,
      payment.statusId,
      this.formatDate(payment.createdAt),
      this.formatDate(payment.expiredAt),
      this.formatDate(payment.initiatedAt),
      this.formatDate(payment.finishedAt),
      payment.reminder,
      this.formatDate(payment.reminderAt),
      payment.paymentMethod,
      `Rp. ${Utils.currencyDigit(Number(payment.packagePrice))}`,
      payment.packageId,
      this.formatDate(payment.packageCreatedAt),
      `Rp. ${Utils.currencyDigit(Number(payment.totalAmountInvoice))}`,
      `Rp. ${Utils.currencyDigit(Number(payment.saldoAmountUsed))}`,
      payment.transactionStatus,
      payment.bank,
      payment.paymentType,
      payment.vaNumber,
    ])
  }

  get params(): Record<string, unknown> {
    return {
      ...this.parameters,
      dateFrom: this.parameters.dateFrom
        ? dayjs(this.parameters.dateFrom, 'YYYY-MM-DD')
            .utc()
            .add(1, 'days')
            .startOf('days')
            .toISOString()
        : null,
      dateTo: this.parameters.dateTo
        ? dayjs(this.parameters.dateTo, 'YYYY-MM-DD')
            .utc()
            .add(1, 'days')
            .endOf('days')
            .toISOString()
        : null,
    }
  }

  @Watch('parameters', { deep: true })
  onParametersChanged(val: Record<string, never>): void {
    if (
      dayjs(val.dateTo, 'YYYY-MM-DD').isBefore(
        dayjs(val.dateFrom, 'YYYY-MM-DD')
      ) ||
      !val.dateTo
    ) {
      this.parameters.dateTo = val.dateFrom
    }
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: {
        ...val,
        dateFrom: this.parameters.dateFrom
          ? dayjs(this.parameters.dateFrom).format('YYYY-MM-DD')
          : null,
        dateTo: this.parameters.dateTo
          ? dayjs(this.parameters.dateTo).format('YYYY-MM-DD')
          : null,
      },
    })
  }

  private fetchSaldoPayment(): void {
    controller.getSaldoPaymentList(this.params)
  }

  private getExportUrl(): void {
    controller.getExportedFile(this.params)
  }

  private formatDate(date: string | undefined): string {
    return date ? dayjs(date).format('DD/MM/YYYY') : '-'
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.DOWNLOAD_SALDO_PAYMENT_READY)
  }
}
